body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

.image-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-snap-align: center;
}

.image-wrapper div {
  width: calc(50% - 1.5rem);
  height: 500px;
  position: relative;
  max-height: 90vh;
  margin: 1.5rem;
  background: var(--white);
  overflow: hidden;
}

.image-wrapper div .single-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.image-wrapper p {
  width: 50%;
  font-size: 1.25rem;
}

.grid-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.grid-image-wrapper img {
  width: 33%;
  flex-grow: 1;
}

/* @media only screen and (min-width: 600px) and (max-width: 991px) {
  .mainImg {
    padding: 1.5rem;
  }
} */

.hidden {
  display: none;
  visibility: hidden;
}


/* .particles-navbar-wrapper {
  position: relative;
}

.particles-navbar-wrapper #tsparticles .test {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */
